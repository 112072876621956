import React, { Suspense, useState, useMemo, useEffect } from "react";
import websitesRoutes from "./routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContext } from "./UserContext";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.scss";
import "./assets/newicons/style.css";
import 'react-datepicker/dist/react-datepicker.css';
import { AuthApi } from "./utils/api";

const { REACT_APP_CLIENT_SESSION_STORAGE_KEY } = process.env;

const spinner = (
	<div className="d-flex justify-content-center align-items-center vh-100">
		<Spinner animation="border" className="text-primary" />
	</div>
)

export default function App() {
	let sessionStorageData = null;
	if (sessionStorage.getItem(REACT_APP_CLIENT_SESSION_STORAGE_KEY) != null) {
		sessionStorageData = JSON.parse(
			sessionStorage.getItem(REACT_APP_CLIENT_SESSION_STORAGE_KEY)
		);
	}

	const getPurchasePlans = async () => {
        try {
            const getPurchasePlansResponse = await AuthApi.get('/get-all-purchased-plans');
            const { data, status } = getPurchasePlansResponse;
            if (status === 200 && data?.status === "SUCCESS") {
                const index = data?.plans?.findIndex((plan) => plan.status === 'Active');
                if (index >= 0) {
                    return data?.plans[index]?.planServices
                }
            }
        } catch (error) {
        }
    }
	
    const getProfileDetails = async () => {
        try {
            const getProfileDetailsResponse = await AuthApi.get(`/get-profile-details`);
            const { data, status } = getProfileDetailsResponse;
            if (status === 200 && data.status === "SUCCESS") {
                const finaldata = await getPurchasePlans(data?.profileDetails)
				sessionStorage.setItem("profileDetails", `${JSON.stringify(data?.profileDetails)}`);
				sessionStorage.setItem("planDetails", `${JSON.stringify(finaldata)}`);
            }
        } catch (error) {
        }
    }
	useEffect(()=>{getProfileDetails()},[])
	const routes = [...websitesRoutes];
	const [user, setUser] = useState(sessionStorageData);
	const value = useMemo(() => ({ user, setUser }), [user, setUser]);

	return (
		<BrowserRouter>
			<Suspense fallback={spinner}>
				<UserContext.Provider value={value}>
					<Routes>
						{routes.map(({ path, name, component: C }, idx) => {
							return (
								<Route key={idx} path={path} name={name} element={<C />} />
							);
						})}
					</Routes>
				</UserContext.Provider>
				<ToastContainer />
			</Suspense >
		</BrowserRouter>
	);
}
