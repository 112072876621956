import { lazy } from 'react';

// Initial Routes
const Login = lazy(() => import("../Views/Login/index"));
const SubLogin = lazy(() => import("../Views/Login/SubLogin"));
const ResetPassword = lazy(() => import("../Views/ResetPassword/index"));
const Dashboard = lazy(() => import("../Views/Dashboard/index"));
const APIDocument = lazy(() => import("../Views/ApiDocument"));
const UsageReports = lazy(() => import("../Views/UsageLogs/Reports"));
const UsageReportsV2 = lazy(() => import("../Views/UsageLogsV2/Reports"));
const UsageLogs = lazy(() => import("../Views/UsageLogs"));
const UsageLogsV2 = lazy(() => import("../Views/UsageLogsV2"));
const CreditBalance = lazy(() => import("../Views/CreditBalance"));
// const AddCredit = lazy(() => import("../Views/CreditBalance/PlansCards"));
const ProfileDetails = lazy(() => import("../Views/ProfileDetails"));
const Invoice = lazy(() => import("../Views/CreditBalance/Invoice"));
const ApiPricing = lazy(() => import("../Views/APIPricing"));
const ApiKeys = lazy(() => import("../Views/APIKeys"));
const OnscreenUsage = lazy(() => import("../Views/OnscreenUsage"));
const Billing = lazy(() => import("../Views/Billing"));
const Support = lazy(() => import("../Views/Support"));
const Registration = lazy(() => import("../Views/Registration"));
const Upload = lazy(() => import("../Views/Upload"));
const SubAccounts = lazy(() => import("../Views/SubAccounts"));
const Reseller = lazy(() => import("../Views/Reseller"));


const websitesRoutes = [
    // Initial Routes
    { path: `/`, exact: true, name: "Login", component: Login },
    { path: `/sub-account-login`, exact: true, name: "Login", component: SubLogin },
    { path: `/reset-password/:requestid/:emailid/:random`, exact: true, name: "Reset password", component: ResetPassword },
    { path: `/dashboard`, exact: true, name: "Dashboard", component: Dashboard },
    { path: `/api-document`, exact: true, name: "APIDocument", component: APIDocument },
    { path: `/usage-reports`, exact: true, name: "Usage Reports", component: UsageReports },
    { path: `/usage-reports-v2`, exact: true, name: "Usage Reports v2", component: UsageReportsV2 },
    // { path: `/usage-reports-v2`, exact: true, name: "Usage Reports v2", component: UsageReportsV2 },
    { path: `/usage-logs/:date`, exact: true, name: "Usage Logs", component: UsageLogs },
    { path: `/usage-logs-v2/:date`, exact: true, name: "Usage Logs v2", component: UsageLogsV2 },
    { path: `/credit-balance`, exact: true, name: "Add Credit", component: CreditBalance },
    // { path: `/add-credit`, exact: true, name: "Add Credit", component: AddCredit },
    { path: `/edit-profile`, exact: true, name: "Edit Profile", component: ProfileDetails },
    { path: `/invoice/:order_id/:order_token`, exact: true, name: "Invoice", component: Invoice },
    { path: `/api-pricing`, exact: true, name: "Api Pricing", component: ApiPricing },
    { path: `/api-keys`, exact: true, name: "Api Keys", component: ApiKeys },
    { path: `/onscreen-usage`, exact: true, name: "Onscreen Usage", component: OnscreenUsage },
    { path: `/billing`, exact: true, name: "Billing", component: Billing },
    { path: `/support`, exact: true, name: "Support", component: Support },
    { path: `/registration/:email`, exact: true, name: "Registration", component: Registration },
    { path: `/upload`, exact: true, name: "upload", component: Upload },
    { path: `/sub-accounts`, exact: true, name: "Sub Account", component: SubAccounts },
    { path: `/reseller-panel`, exact: true, name: "Reseller", component: Reseller }
];

export default websitesRoutes;