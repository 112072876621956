import axios from "axios";
import { toast } from "react-toastify";
import { checkDataIsValid } from "./secure";

const tokenExpiredError = "Not Authorized";

const { REACT_APP_API_URL, REACT_APP_CLIENT_SESSION_STORAGE_KEY, REACT_RPACPC_API_URL } = process.env;

const SERVER_BASE_URL = REACT_APP_API_URL;
const RPACPC_API_URL = REACT_RPACPC_API_URL
let token = null;
let data = sessionStorage.getItem(REACT_APP_CLIENT_SESSION_STORAGE_KEY);
token = JSON.parse(data);

const GuestApi = axios.create({
	baseURL: SERVER_BASE_URL + "/client",
});


const getToken = () => {
	let data = sessionStorage.getItem(REACT_APP_CLIENT_SESSION_STORAGE_KEY);
	if (!data) {
		return null;
	}
	return JSON.parse(data);
};

const AuthApi = axios.create({
	baseURL: SERVER_BASE_URL + "/client",
});
const ServiceAuthApi = axios.create({
	baseURL: "https://api.rpacpc.com/services",
	// baseURL: "http://localhost:4000/services",
});

const setToken = (accesstoken) => {
	const data = sessionStorage.getItem(REACT_APP_CLIENT_SESSION_STORAGE_KEY);
	const tokendata = JSON.parse(data);
	tokendata.accessToken = accesstoken;
	sessionStorage.setItem(
		REACT_APP_CLIENT_SESSION_STORAGE_KEY,
		`${JSON.stringify(tokendata)}`
	);
};



AuthApi.interceptors.request.use(
	(config) => {
		if (token) {
			config.headers["Authorization"] = `${token?.accessToken}`;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

AuthApi.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		let originalRequest = error.config;
		const { response: errRes } = error;
		const { status, data } = errRes;
		if (
			errRes &&
			status === 401 &&
			checkDataIsValid(data) &&
			Object.keys(data).length &&
			checkDataIsValid(data.errors)
		) {
			const errors = data.errors;
			if (errors.length > 0) {
				const name = errors[0].message;

				if (name === tokenExpiredError && !originalRequest._retry) {
					originalRequest._retry = true;
					const sendData = {
						refreshToken_id: token?.refreshToken_id,
						refresh_token: token?.refreshToken,
					};
					return GuestApi.post("/token", { ...sendData })
						.then((res) => {
							const { status: ref_status, data: ref_data } = res;
							if (ref_status === 201 && ref_data.status === "SUCCESS") {
								const { accessToken } = ref_data;
								originalRequest.headers[
									"Authorization"
								] = `${accessToken}`;
								setToken(accessToken);
								window.location.reload();
								return axios(originalRequest);
							}
						})
						.catch((e) => {
							const { response } = e;
							if (response.status === 403) {
								sessionStorage.removeItem("client_data");
								window.location.reload();
							}
						});
				}
			}
			// toast.warning(`Session expired....Please Login to continue!`);
			return Promise.reject(error);
		}
		// toast.warning(`Session expired....Please Login to continue!`);
		return Promise.reject(error);
	}
);

export {
	GuestApi,
	AuthApi,
	ServiceAuthApi,
	getToken,
};